<template>
    <div>
        <div class="header-container">
            <Header />
        </div>
        <!-- <div class="custome-service">
            <div class="service-item">
                <el-tooltip class="item" effect="light" content="Top Center 提示文字" placement="left" trigger="click" :hide-after="30000" ref="weixintooltip">
                    <div slot="content">
                        <p class="tooltip-title">扫码添加微信客服</p>
                        <img src="../assets/images/weichat.jpg" alt="" style="width:177px;height:177px">
                    </div>
                    <div>
                        <p  class="iconfont icon-weixin" ref="weixin"></p>
                            <p class="service-title">微信客服</p>
                    </div>
                </el-tooltip>
            </div>
            <div class="service-line"></div>
            <div class="service-item">
                    <el-tooltip class="item" effect="light" content="Top Center 提示文字" placement="left" trigger="click" :hide-after="30000" ref="weixintooltip">
                    <div slot="content">
                        <p class="tooltip-title" style="margin-bottom:10px">客服电话</p>
                        <p>010-57188208</p>
                    </div>
                    <div>
                        <span  class="iconfont icon-dianhua"></span>
                        <p class="service-title">电话客服</p>
                    </div>
                </el-tooltip>
                
            </div>
        </div> -->
        <div class="main-content">
            <router-view></router-view>
        </div>
        <div class="footer-container">
            <Footer />
        </div>
    </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
    export default {
        name:'layout',
        components:{
            Header,
            Footer
        },
        data () {
            return {
               
            }
        },
        mounted() {
            this.init();
        },
        methods: {

            init(){

            },
            add(){

            },
            edit(){

            },
        },
        computed: {

        },
        watch: {

        }
    }
</script>

<style lang="less" scoped>
.header-container{
    height: 68px;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--header-color);
    z-index: 999;
   
}
.main-content{
    min-height: calc(50vh);
}

.custome-service{
    position: fixed;
    right: 0px;
    top: 35%;
    z-index: 999;
    width: 73px;
    font-size: 20px;
    text-align: center;
    background: #fff;
    box-shadow: 0 0 10px 2px #bcb6b6;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 20px 0 20px 10px;
    .service-item{
        cursor: pointer;
    }
    .service-line{
        width: 42px;
        height: 1px;
        background: #CCCCCC;
        margin:0 auto;
        margin-top:20px;
        margin-bottom:20px;
    }
    .iconfont{
        font-size: 40px;
    }
    .tooltip-title{
        margin-bottom: 10px;
    }
    .service-title{
        font-size: 12px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #939393;
        margin-top:6px;
    }
}
.footer-container{
    position: relative;
}
</style>
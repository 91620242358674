import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'
import { getHashQueryString } from '@/utils/tool'
export const basicRoute  = [
    {
        path: '/',
        name: 'home',
        component: Layout,
        meta: { title: '首页' },
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import(/* webpackChunkName: "user" */ '@/views/home/Home.vue')
            },
            {
                path: '/register',
                name: 'register', 
                component: () => import(/* webpackChunkName: "user" */ '@/views/register/Index.vue')
            },
            {
                path: '/query',
                name: 'query',
                component: () => import(/* webpackChunkName: "user" */ '@/views/query/Index.vue')
            },
            {
                path: '/mediate',
                name: 'mediate',
                component: () => import(/* webpackChunkName: "user" */ '@/views/mediate/Index.vue')
            },

            {
                path:'/study',
                name:'study',
                component: () => import(/* webpackChunkName: "user" */ '@/views/study/Index.vue')
            },
            {
                path:'/activity',
                name:'activity',
                component: () => import(/* webpackChunkName: "user" */ '@/views/activity/Index.vue')
            },
            {
                path: '/activityDetails/:id/:type',
                name: 'activityDetails', 
                meta:{
                    name:'版权活动'
                },
                component: () => import(/* webpackChunkName: "user" */ '@/views/activity/Detail.vue')
            },
            {
                path: '/work/authentication',
                name: 'register', 
                component: () => import(/* webpackChunkName: "user" */ '@/views/works/authentication.vue'),
                meta:{
                    name:'作品认证'
                },
            },
            {
                path: '/work/announcement',
                name: 'register', 
                component: () => import(/* webpackChunkName: "user" */ '@/views/works/announcement.vue'),
                meta:{
                    name:'认证公示'
                },
            },
            {
                path: '/work/check',
                name: 'register', 
                component: () => import(/* webpackChunkName: "user" */ '@/views/works/check.vue'),
                meta:{
                    name:'认证查验'
                },
            },
            {
                path: '/work/check/error',
                name: 'register', 
                component: () => import(/* webpackChunkName: "user" */ '@/views/works/WorkCheckError.vue'),
                meta:{
                    name:'查验失败'
                },
            },
            {
                path: '/work/check/success',
                name: 'register', 
                component: () => import(/* webpackChunkName: "user" */ '@/views/works/WorkCheckSucc.vue'),
                meta:{
                    name:'查验结果'
                },
            },
        ]
    },
]

Vue.use(Router)
let stationCode = getHashQueryString('code');
console.log(stationCode,'工作站编码')
if(stationCode){
    sessionStorage.setItem('STATION_CODE',stationCode)
}
export default new Router({
  mode: 'hash',
//   mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: basicRoute
})
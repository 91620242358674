/**
 * 获取hash路由# 后的参数
 * @param {*}
 */
export function getHashQueryString(val) {
 
    const w = location.hash.indexOf('?');
    const query = location.hash.substring(w + 1);
  
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] == val) { return pair[1]; }
    }
  
    return (false);
  }